import React, {Component} from 'react';
import './SessionDetails.less';

export type Session = {
    id: string;
    isImpersonating: boolean;
    principalUuid: string;
    subjectUuid: string;
};

class SessionDetails extends Component<{session: Session}> {
    render() {
        let impersonator = `You are impersonator with UUID:${this.props.session.principalUuid}`;
        let imppersonating = `You are impersonating subject UUID:${this.props.session.subjectUuid}`;
        const userInfo = (
            <div>
                <h1> {'Details of your current session'} </h1>
                <p>{impersonator}</p>
                <p>{imppersonating}</p>
            </div>
        );
        return userInfo;
    }
}

export default SessionDetails;
