import rebrandBannerDataReducer from './reducer_rebrandBannerData';

/**
 * Connect reducers to the redux store.
 */

const reducers = (state, action) => {
    return {
        ...state,
        bannerData: rebrandBannerDataReducer(state.bannerData, action),
    };
};

export type ReducerState = ReturnType<typeof reducers>;

export default reducers;
