import React, {Component} from 'react';
import {connect} from 'react-redux';
import SiteHeader from '@homeaway/react-site-header';
import BirdhouseComponent from '../BirdhouseComponent/BirdhouseComponent';
import TransitionBannerContainer, {
    BannerProps,
} from '../TransitionBannerContainer/TransitionBannerContainer';
import './BirdhouseContainer.less';

function mapStateToProps(state) {
    // Each container component will likely only pull in the state that
    // it cares about. Doing so will eliminate unnecessary rerenders.
    return {
        site: state.site,
        serviceUrl: state.serviceUrl,
        experiments: state.experiments,
        ticket: state.ticket,
        landingPage: state.landingPage,
        isLegacy: state.isLegacy,
        isOwner: state.isOwner,
        isCreatePassword: state.isCreatePassword,
        actionLocation: state.actionLocation,
        edapEventLoginSource: state.edapEventLoginSource,
        bannerData: state.bannerData,
        skipCas: state.skipCas,
        insecureFlow: state.insecureFlow,
        siteCheckEnabled: state.siteCheckEnabled,
        preferenceType: state.marketingOptInType.sign_up,
        conversationId: state.conversationId,
        userProfile: state.userProfile,
        configContext: state.configContext,
    };
}

export type ServiceUrl = string;

export type UserProfile = {
    emailAddress: string;
    firstName: string;
    lastName: string;
};

export type Site = {
    siteName: string;
    authName: string;
    theme: string;
    siteReadableName: string;
    locale: string;
};

export type BirdhouseProps = {
    landingPage: string;
    skipCas: boolean;
    serviceUrl: ServiceUrl;
    isCreatePassword: boolean;
    site: Site;
    isOwner: boolean;
    ticket: string;
    isLegacy: boolean;
    actionLocation: string;
    edapEventLoginSource: string;
    insecureFlow: boolean;
    siteCheckEnabled: boolean;
    preferenceType: string;
    conversationId: string;
    userProfile: UserProfile;
    configContext: string;
};

class BirdhouseContainer extends Component<BirdhouseProps & BannerProps> {
    componentDidMount() {
        window.ha.userTiming.mark('primary.action.rendered');
        document.body.classList.add(
            `body-bg-color--${this.props.site.siteName}`
        );
    }

    render() {
        const siteHeaderProps = {
            dataUrl: '/traveler/api/header',
            siteName: this.props.site.siteName,
        };

        return (
            <div className="center-block text-center BirdhouseContainer">
                <SiteHeader
                    {...siteHeaderProps}
                    withoutTray
                    withoutNav
                    withoutListYourProperty
                />
                {this.props.isOwner &&
                    Object.keys(this.props.bannerData).length !== 0 && (
                        <div className="text-left">
                            <TransitionBannerContainer
                                bannerData={this.props.bannerData}
                                experiments={this.props.experiments}
                                site={this.props.site}
                            />
                        </div>
                    )}
                <BirdhouseComponent
                    site={this.props.site}
                    serviceUrl={this.props.serviceUrl}
                    ticket={this.props.ticket}
                    landingPage={this.props.landingPage}
                    isLegacy={this.props.isLegacy}
                    isOwner={this.props.isOwner}
                    isCreatePassword={this.props.isCreatePassword}
                    actionLocation={this.props.actionLocation}
                    edapEventLoginSource={this.props.edapEventLoginSource}
                    skipCas={this.props.skipCas}
                    insecureFlow={this.props.insecureFlow}
                    siteCheckEnabled={this.props.siteCheckEnabled}
                    preferenceType={this.props.preferenceType}
                    conversationId={this.props.conversationId}
                    userProfile={this.props.userProfile}
                    configContext={this.props.configContext}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps)(BirdhouseContainer);
