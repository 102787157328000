import React, {Component} from 'react';
import LEX from '@homeaway/react-lex';

export type ServiceUrl = string;

export type Site = {
    siteName: string;
    authName: string;
    theme: string;
    siteReadableName: string;
    locale: string;
};

export type UserProfile = {
    emailAddress: string;
    firstName: string;
    lastName: string;
};

export type BirdhouseProps = {
    landingPage: string;
    skipCas: boolean;
    serviceUrl: ServiceUrl;
    isCreatePassword: boolean;
    site: Site;
    isOwner: boolean;
    ticket: string;
    isLegacy: boolean;
    actionLocation: string;
    edapEventLoginSource: string;
    insecureFlow: boolean;
    siteCheckEnabled: boolean;
    preferenceType: string;
    conversationId: string;
    userProfile: UserProfile;
    configContext: string;
};

type BirdhouseState = {
    serviceUrl: ServiceUrl;
};

class BirdhouseComponent extends Component<BirdhouseProps, BirdhouseState> {
    state: BirdhouseState = {
        serviceUrl: this.props.serviceUrl,
    };

    redirectPostLogin = () => {
        if (typeof window !== 'undefined') {
            let url;
            if (this.props.landingPage === 'success' || this.props.skipCas) {
                url = this.state.serviceUrl;
            } else {
                // Need small hack for rebranded sites since CAS does not handle those :(
                let serviceUrl = this.state.serviceUrl;
                let siteParam = '';
                if (this.props.isCreatePassword === true) {
                    serviceUrl =
                        typeof this.state.serviceUrl === 'undefined'
                            ? ''
                            : this.state.serviceUrl.replace(
                                  /(?:\/([a-z]{2}-[a-z]{2})(?:\/)?)/g,
                                  '/'
                              );
                    siteParam = `&site=${this.props.site.siteName}`;
                }
                if (this.props.isOwner === true) {
                    const siteAuthName = this.props.site.authName;
                    url = `/auth/${siteAuthName}/login?service=${encodeURIComponent(
                        serviceUrl
                    )}&authui=false${siteParam}`;
                } else {
                    url = `/auth/traveler/login?service=${encodeURIComponent(
                        serviceUrl
                    )}${
                        this.props.ticket === ''
                            ? `&authui=false${siteParam}`
                            : `${siteParam}`
                    }`;
                }
            }
            window.location.href = url;
        }
    };

    render() {
        // Check to see if we are being server side rendered
        let service = typeof window !== 'undefined' ? window.location.href : '';

        return (
            <div className="BirdhouseComponent">
                <LEX
                    service={service}
                    locale={this.props.site.locale}
                    site={this.props.site.siteName}
                    siteName={this.props.site.siteReadableName}
                    handleLoginSuccess={this.redirectPostLogin}
                    serverConfigRendering
                    ticketId={this.props.ticket}
                    landingPage={this.props.landingPage}
                    theme={this.props.site.theme}
                    isLegacy={this.props.isLegacy}
                    isCreatePassword={this.props.isCreatePassword}
                    isOwner={this.props.isOwner}
                    actionLocation={this.props.actionLocation}
                    edapEventLoginSource={this.props.edapEventLoginSource}
                    insecureFlow={this.props.insecureFlow}
                    siteCheckEnabled={this.props.siteCheckEnabled}
                    preferencePageType={'auth-ui'}
                    preferenceType={this.props.preferenceType}
                    conversationId={this.props.conversationId}
                    emailAddress={this.props.userProfile?.emailAddress}
                    firstName={this.props.userProfile?.firstName}
                    lastName={this.props.userProfile?.lastName}
                    configContext={this.props.configContext}
                />
            </div>
        );
    }
}

export default BirdhouseComponent;
