export type RebrandBannerDataState = {
    vgcdParam?: string;
    translations?: {[key: string]: string};
};

export type RebrandBannerDataActions = {
    type: 'SET_REBRAND_BANNER_DATA';
    payload: RebrandBannerDataState;
};

export default function (
    state: RebrandBannerDataState = {},
    action: RebrandBannerDataActions
) {
    switch (action.type) {
        case 'SET_REBRAND_BANNER_DATA':
            return {...state, ...action.payload};
        default:
            return state;
    }
}
