import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, Route} from 'react-router-dom';
import BirdhouseContainer from './components/BirdhouseContainer/BirdhouseContainer';
import SessionCheckContainer from './components/SessionCheckContainer/SessionCheckContainer';
import {ReducerState} from './reducers/all';
import {Store} from 'redux';

/**
 * Basic provider wrapping. This has been externalized in order to
 * enable hot module replacement features.
 */

/* istanbul ignore next */
function Root({
    store,
    siteContext,
}: {
    store: Store<ReducerState>;
    siteContext: SiteContext;
}) {
    return (
        <Provider store={store as any}>
            <BrowserRouter basename={siteContext.basePath}>
                <div>
                    <Route
                        path="/auth/ui/login"
                        component={BirdhouseContainer}
                    />
                    <Route
                        path="/auth/ui/resetPassword"
                        component={BirdhouseContainer}
                    />
                    <Route
                        path="/auth/ui/confirmEmail"
                        component={BirdhouseContainer}
                    />
                    <Route
                        path="/auth/ui/sessionCheck"
                        component={SessionCheckContainer}
                    />
                </div>
            </BrowserRouter>
        </Provider>
    );
}

export default Root;
