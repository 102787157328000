import React, {Component} from 'react';
import SessionDetails, {Session} from '../SessionDetails/SessionDetails';

class SessionCheckContainer extends Component<{
    session: Session;
}> {
    render() {
        const {session} = this.props;

        if (session && session.isImpersonating === true) {
            return <SessionDetails session={session} />;
        }
        return (
            <div>
                <h1 className="sessionState">
                    {' '}
                    {'Session found but not impersonating'}{' '}
                </h1>
            </div>
        );
    }
}

export default SessionCheckContainer;
