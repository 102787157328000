// Polyfills
import 'whatwg-fetch';
import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import {ApolloProvider} from 'react-apollo';
import getCurrentScriptPath from '@homeaway/current-script-path';
import {createStore} from './store';
import configureApolloClient from './apollo/client';
import Root from './Root';

/**
 * Main client-side entry point. This is not used during
 * server-side rendering, only after the javascript bundle
 * has been loaded into the browser.
 */

// Let webpack know where to load files from in production envs.
// This is primarily for code-splitting scenarios and for images.
if (!module.hot) {
    // @ts-ignore
    __webpack_public_path__ = getCurrentScriptPath(); // eslint-disable-line no-undef, camelcase
}

// Initial state as set by the server-side render
const initialState = window.__INITIAL_STATE__;
const siteContext = window.__SITE_CONTEXT__;

const store = createStore(initialState);
const client = configureApolloClient();

ReactDOM.hydrate(
    <ApolloProvider client={client}>
        <Root store={store} siteContext={siteContext} />
    </ApolloProvider>,
    document.getElementById('root')
);
