import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createHttpLink} from 'apollo-link-http';
import {ApolloLink} from 'apollo-link';

let client;

const HEADER_CSRF = 'x-csrf-jwt';

const httpLink = createHttpLink({
    uri: '/auth/ui/graphql',
    credentials: 'same-origin',
    headers: {
        'client-name': 'auth-ui',
        'client-version': '1.0.0',
        'x-homeaway-site': window.__SITE_CONTEXT__.name,
    },
});

const csrfLink = new ApolloLink((operation, forward) => {
    operation.setContext((prevContext) => ({
        headers: {
            ...prevContext.headers,
            [HEADER_CSRF]: window.ha.csrfToken,
        },
    }));

    return forward(operation).map((response) => {
        const {
            response: {headers},
        } = operation.getContext();
        if (headers.has(HEADER_CSRF)) {
            window.ha.csrfToken = headers.get(HEADER_CSRF);
        }
        return response;
    });
});

const createApolloClient = () =>
    new ApolloClient({
        cache: new InMemoryCache({addTypename: false}),
        link: ApolloLink.from([csrfLink, httpLink]),
    });

const getApolloClient = () => {
    if (!client) {
        client = createApolloClient();
    }

    return client;
};

export default getApolloClient;
