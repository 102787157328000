import React from 'react';
import Cookie from 'js-cookie';

import {
    TransitionBanner,
    HOSTAPP,
} from '@homeaway/react-brand-transition-banner';

type Experiment = {
    value: boolean;
};

type Experiments = {[key: string]: Experiment};

type Site = {
    id: string;
    locale: string;
    siteName: string;
    siteReadableName: string;
    theme: string;
    authName: string;
};

type BannerData = {
    vgdcParam?: string;
    locale?: string;
    siteName?: string;
};

export type BannerProps = {
    bannerData: BannerData;
    experiments: Experiments;
    site: Site;
};

const TransitionBannerContainer = ({
    bannerData,
    experiments,
    site,
}: BannerProps) => {
    const isPreBannerTest =
        experiments.HA_GX_PRE_BRAND_TRANSITION_PX &&
        experiments.HA_GX_PRE_BRAND_TRANSITION_PX.value
            ? true
            : false;
    const isPostBannerTest =
        experiments.HA_GX_POST_BRAND_TRANSITION_PX &&
        experiments.HA_GX_POST_BRAND_TRANSITION_PX.value
            ? true
            : false;

    const vgdcParam = bannerData.vgdcParam || Cookie.get('vgdc');
    const locale = bannerData.locale || site.locale;

    if (isPreBannerTest) {
        return (
            <TransitionBanner
                useCookie
                forPreTransition
                useCookieOnClose
                locale={locale}
                siteName={bannerData.siteName}
                hostApp={HOSTAPP.OWNER_LOGIN}
            />
        );
    } else if (isPostBannerTest) {
        return (
            <TransitionBanner
                useCookie={false}
                forPreTransition={false}
                vgdc={vgdcParam}
                locale={locale}
                siteName={bannerData.siteName}
                hostApp={HOSTAPP.OWNER_LOGIN}
            />
        );
    }
    return null;
};

export default TransitionBannerContainer;
