/* istanbul ignore next */
const ACTIONS = {
    TRIGGER_EDAP_PAGEVIEW: 'TRIGGER_EDAP_PAGEVIEW',
    TRIGGER_EDAP_EVENT: 'TRIGGER_EDAP_EVENT',
};

/* istanbul ignore next */
const ACTION_CREATORS = {
    triggerEdapPageView() {
        return {
            type: ACTIONS.TRIGGER_EDAP_PAGEVIEW,
        };
    },

    triggerEdapEvent(eventName, eventData, options) {
        return {
            type: ACTIONS.TRIGGER_EDAP_EVENT,
            payload: {
                eventName,
                eventData,
                options,
            },
        };
    },
};

export default {...ACTIONS, ...ACTION_CREATORS};
